import { useState } from 'react'
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import {
  Person,
  ArrowBackRounded,
  Money
} from '@mui/icons-material'
import { BACKOFFICE_URL } from '@config/environment'

// STYLES
import * as Styled from './SideBar.styles'

const SideBar = (): JSX.Element => {
  const drawerWidth = 220

  const menuItems = [
    {
      id: 1,
      name: 'Gerenciar Carteira',
      icon: <Person />,
      link: '/apps/coins/wallet/',
    },
    {
      id: 2,
      name: 'Créditos via csv',
      icon: <Money />,
      link: '/apps/coins/credits/upload',
    },
  ]

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'primary.main',
        },
      }}
    >
      <Toolbar sx={{ mt: 2 }} />
      <List component="nav" disablePadding>
        {menuItems.map((item) => (
          <List component="div" key={item.id} disablePadding>
            <Styled.LinkMenu to={item.link}>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.name}
                  disableTypography
                  sx={{ fontWeight: '300' }}
                />
              </ListItem>
            </Styled.LinkMenu>
          </List>
        ))}
      </List>
      <Styled.LinkOldBackoffice
        href={`${BACKOFFICE_URL}/marketplace`}
        title={`Voltar para o backoffice da multigaming`}
        target="_blank"
      >
        <ArrowBackRounded />
        backoffice multigaming
      </Styled.LinkOldBackoffice>
      <Styled.Footer>
        &copy; {new Date().getFullYear()}{' '}
        <Styled.FooterLink href="http://gamersclub.com.br" target="_blank">
          GamersClub
        </Styled.FooterLink>{' '}
        made with ❤️ for a better backoffice
      </Styled.Footer>
    </Drawer>
  )
}

export { SideBar }
