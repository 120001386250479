// API
import { coinsApi } from '@shared/api/coins/coinsApi'
import { inventoryApi } from '@shared/api/inventory/inventoryApi'

// TYPES
import {
  WalletSummary,
  WalletReason,
  WalletEvents,
  WalletCoinsDebits,
  UpdateBalancePayload,
  WalletBalance,
  RemoveProductRequestProps,
  SendCreditsResponse,
  Credits,
  SendCreditsPayload,
} from '@shared/types/CoinsTypes'

const getWalletSummary = (userId: string): Promise<WalletSummary> => {
  return coinsApi.get(`/v1/backoffice/wallet/summary/${userId}`)
}

const getReasons = (): Promise<WalletReason> => {
  return coinsApi.get(`/v1/backoffice/reasons`)
}

const getWalletEventsTransactions = (
  userId: string,
  data?
): Promise<WalletEvents> => {
  const queryString = data ? `${new URLSearchParams(data).toString()}` : ''

  return coinsApi.get(
    `/v1/backoffice/wallet/events/account/${userId}?${queryString}`
  )
}

const getWalletCoinsTransactions = (
  type: string, // hardCoins ou softCoins
  userId: string,
  data?
): Promise<WalletCoinsDebits> => {
  const queryString = data ? `${new URLSearchParams(data).toString()}` : ''

  return coinsApi.get(
    `/v1/backoffice/wallet/debits?type=${type}&accountId=${userId}&${queryString}`
  )
}

const getWalletGiftsTransactions = (
  userId: string,
  source: string,
  data?
): Promise<WalletCoinsDebits> => {
  const queryString = data ? `${new URLSearchParams(data).toString()}` : ''

  return coinsApi.get(
    `/v1/backoffice/wallet/debits?type=hardCoins&accountId=${userId}&${queryString}&eventSources[0]=${source}`
  )
}

const updateCoinsBalance = (
  data: UpdateBalancePayload
): Promise<WalletBalance> => {
  return coinsApi.patch(`/v1/backoffice/wallet/balance/update`, data)
}

const deleteInventoryItemFromPlayer = (
  itemId: number
): Promise<RemoveProductRequestProps> => {
  return inventoryApi.delete(`/v1/backoffice/items/${itemId}`)
}

const sendCredits = (
  data: SendCreditsPayload
): Promise<SendCreditsResponse> => {
  return coinsApi.post('/v1/backoffice/wallet/credit/batch', data)
}

const sendCreditsWithCsvFile = (data: FormData): Promise<Credits> => {
  return coinsApi.post('/v1/backoffice/wallet/credit/upload', data)
}

export {
  getWalletSummary,
  getReasons,
  getWalletEventsTransactions,
  getWalletCoinsTransactions,
  getWalletGiftsTransactions,
  updateCoinsBalance,
  deleteInventoryItemFromPlayer,
  sendCredits,
  sendCreditsWithCsvFile,
}
