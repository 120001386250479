import axios from 'axios'
import { INVENTORY_API_URL } from '@config/environment'

// SHARED INTERCEPTORS
import { _shared } from '../../../../../root/src/app/shared/shared'
const shared = _shared

const inventoryApi = axios.create({
  baseURL: INVENTORY_API_URL,
})

inventoryApi.interceptors.request.use(shared.xProductInterceptor)
inventoryApi.interceptors.response.use(
  shared.responseInterceptor,
  shared.checkTokenValidityInterceptor
)

export { inventoryApi }
