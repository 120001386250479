import * as React from 'react'

// SERVICES
import {
  getReasons,
  updateCoinsBalance
} from '@services/coins/CoinsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Button,
  InputLabel,
  Grid,
  Select,
  OutlinedInput,
  MenuItem,
  Card,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { CurrencyTypeEnum } from '@shared/types/CoinsTypes'

import { ModalProps } from './types'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
const Modal = ({ handleClose, open, addCoins, accountId }: ModalProps): JSX.Element => {
  const [currencyType, setCurrencyType] = React.useState<string>('')
  const [value, setValue] = React.useState<number>(0)
  const [reason, setReason] = React.useState<string>('')
  const [reasonItems, setReasonItems] = React.useState([])

  React.useEffect(() => {
    getReasons().then((response) => setReasonItems(response.reasons))
  }, [])

  const transactionType = addCoins ? 'add' : 'remove'

  const validate = (value, currencyType, actionReason) => {
    return actionReason !== -1 &&
      value > 0 &&
      currencyType !== ''
  }

  const isValid = validate(value, currencyType, reason)
  
  const _updateCoinsBalance = async (): Promise<void> => {
    try {
      const payload = {
        accountId,
        transactionType,
        coinsType: currencyType,
        amount: value,
        reasonId: reason
      }
      await updateCoinsBalance(payload)
      handleClose()
      Alert.success('Carteira atualizada com sucesso, a pagina atualizará em breve.')
      setTimeout(function(){
        window.location.reload();
     }, 3000);
    } catch {
      handleClose()
      Alert.error('Erro ao atualizar essa carteira')
    }
  }

  const renderCoinOptions = Object.keys(CurrencyTypeEnum).map(key => (
    <MenuItem
      value={key}
      key={key}
    >
      {CurrencyTypeEnum[key]}
    </MenuItem>
  ))

  const renderReasonOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.reason}
        </MenuItem>
      ))
    )
  }
  
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="add-remove-coins"
        open={open}
        maxWidth={'md'}
      >
        <BootstrapDialogTitle
          id="add-remove-coins"
          onClose={handleClose}
        >
          {addCoins ? 'Adicionar moedas à carteira' : 'Remover moedas da carteira'}
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputLabel shrink>
                Tipo de moeda *
              </InputLabel>
                <Select
                  required
                  fullWidth={true}
                  key="currencyType"
                  value={currencyType}
                  onChange={(e) => setCurrencyType(e.target.value)}
                  input={<OutlinedInput label="Name" />}
                >
                  {renderCoinOptions}
                </Select>
              <span style={{ fontSize: '0.8em', lineHeight: '0.6em' }}>Escolha qual tipo de moeda deve ser adicionada à carteira do usuário.</span>
            </Grid>
            <Grid item xs={4}>
              <InputLabel shrink>
                Valor *
              </InputLabel>
              <TextField
                fullWidth={true}
                type="number"
                value={value}
                onChange={(e) => setValue(parseInt(e.target.value))}
              />
              <span style={{ fontSize: '0.8em', lineHeight: '0.6em' }}>Insira o valor que deve ser adicionado à carteira do usuário.</span>
            </Grid>
            <Grid item xs={4}>
              <InputLabel shrink>
                Motivo da adição *
              </InputLabel>
              <Select
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                fullWidth={true}
              >
                {renderReasonOptions(reasonItems)}
              </Select>
              <span style={{ fontSize: '0.8em', lineHeight: '0.6em' }}>Escolha o motivo da adição de moedas.</span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" type="submit" onClick={handleClose}>
            Cancelar
          </Button>
          <Button 
            variant="contained" 
            color={'success'} 
            type="submit" 
            disabled={!isValid}
            onClick={() => _updateCoinsBalance()}
          >
            {addCoins ? 'Adicionar' : 'Remover'}
          </Button>
        </DialogActions>
        </BootstrapDialog>
    </div>
  )
}

export { Modal }
