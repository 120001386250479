import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  sendCredits,
  sendCreditsWithCsvFile,
} from '@services/coins/CoinsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Card,
  OutlinedInput,
  TextField,
  Select,
  Box,
  Typography,
  InputLabel,
  MenuItem,
} from '@mui/material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { CsvUpload } from '@shared/components/CsvUpload/CsvUpload'

// ICON
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'

const CreditsForm = (): JSX.Element => {
  const [softCoins, setSoftCoins] = useState<number>(0)
  const [hardCoins, setHardCoins] = useState<number>(0)
  const [csvFile, setCsvFile] = useState<string | null>(null)
  const [csvContents, setCsvContents] = useState<string[]>([])
  const [target, setTarget] = useState<string>('none')

  const navigate = useNavigate()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (csvFile) {
      _sendCreditsWithCsvFile()
    } else {
      _sendCredits()
    }
  }

  const _sendCredits = async (): Promise<void> => {
    try {
      const data = {
        softCoins,
        hardCoins,
      }
      const csvContent = csvContents
        .map((csvContent) =>
          csvContent
            .replace(/[\r'" ]/g, '')
            .replace(/(,+|;+|\s+)/g, '\n')
            .replace(/(\n+)/g, '\n')
            .replace(/^\n|\n$/g, '')
            .split(/[\n]/g)
        )
        .reduce((result, currentValue): string[] => {
          result.push(...currentValue)

          return result
        }, [])

      const payload = {
        accountIds: csvContent,
        credits: data,
      }

      await sendCredits(payload)

      Alert.success(
        'Créditos enviados com sucesso, a página será atualizada em breve'
      )
      setTimeout(function () {
        window.location.reload()
      }, 3000)
    } catch (err) {
      Alert.error('Ocorreu um erro ao realizar o envio dos créditos')
    }
  }

  const _sendCreditsWithCsvFile = async (): Promise<void> => {
    try {
      const formData = new window.FormData()
      formData.append('file', csvFile)
      formData.append('softCoins', softCoins.toString())
      formData.append('hardCoins', hardCoins.toString())

      await sendCreditsWithCsvFile(formData)

      Alert.success(
        'Créditos enviados com sucesso, a página será atualizada em breve'
      )
      setTimeout(function () {
        window.location.reload()
      }, 3000)
    } catch (err) {
      Alert.error('Ocorreu um erro ao realizar o envio dos créditos')
    }
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Adicionar Créditos">
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const handleFileChange = (rawFile, readerResult) => {
    setCsvFile(rawFile)
  }

  const handleRemoveFile = () => {
    setCsvFile(null)
  }

  return (
    <Card sx={{ width: 600 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <TextField
                required
                label="Quantidade de Silver"
                variant="outlined"
                fullWidth
                type="number"
                onChange={(e) => setSoftCoins(Number(e.target.value))}
                value={softCoins}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Quantidade de Gold"
                variant="outlined"
                fullWidth
                type="number"
                onChange={(e) => setHardCoins(Number(e.target.value))}
                value={hardCoins}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" gutterBottom>
                Use o formato abaixo em qualquer um dos tipos:
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                fbd27fa7-312c-4006-a8ac-8c855093b3eb
                <br />
                3f3480d2-6f21-492e-b452-53ab70c8a6b8
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" gutterBottom>
                * Para enviar um aqruivo csv selecione o tipo de envio CSV
              </Typography>
              <InputLabel shrink>Tipo de envio *</InputLabel>
              <Select
                required
                fullWidth={true}
                key="target"
                value={target}
                onChange={(e) => setTarget(e.target.value)}
              >
                <MenuItem value="none">
                  <p>Selecione uma opção</p>
                </MenuItem>
                <MenuItem value={'CSV'}>CSV</MenuItem>
                <MenuItem value={'TEXT'}>TEXT</MenuItem>
              </Select>
            </Grid>
            {target === 'CSV' && (
              <>
                <Grid item xs={4}>
                  <CsvUpload
                    defaultCsv={csvFile}
                    restrictions=".csv"
                    handleSubmit={(rawFile, readerResult) => {
                      handleFileChange(rawFile, readerResult)
                    }}
                  />
                </Grid>
                {csvFile && (
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      onClick={() => handleRemoveFile()}
                    >
                      Remover CSV
                    </Button>
                  </Grid>
                )}
              </>
            )}
            {target === 'TEXT' && (
              <Grid item xs={12}>
                <TextField
                  label="Lista de Account Ids"
                  variant="outlined"
                  fullWidth
                  multiline={true}
                  rows={5}
                  onChange={(e) => setCsvContents([e.target.value])}
                  value={csvContents}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs>
            <Grid item xs sx={{ mt: 5 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  Adicionar créditos
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { CreditsForm }
