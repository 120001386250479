import styled from 'styled-components'

export const TransactionValue = styled.div`
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    margin-right: 6px;
  }

  svg {
    margin-right: 4px;
  }
`

