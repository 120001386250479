import React from 'react'

// @material-ui/icons
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

// ASSETS
import GoldCoinIcon from '@assets/svg/goldCoin.svg'
import SilverCoinIcon from '@assets/svg/silverCoin.svg'
import DisableCoinIcon from '@assets/svg/disabledCoin.svg'

import { CurrencyTypeEnum } from '@shared/types/CoinsTypes'

// STYLES
import * as Styled from './styles'

const renderArrow = (gainCoins) => {
  return gainCoins !== undefined && gainCoins !== null && (gainCoins
    ? <ArrowUpward color='success'/>
    : <ArrowDownward color='error'/>)
}

const renderCoin = (type) => {
  if(!type) {
    <img
      src={DisableCoinIcon}
      alt="Sem logo"
    />
  }

  return <img
    src={CurrencyTypeEnum.hardCoins === type ? GoldCoinIcon : SilverCoinIcon}
    alt="Logo"
  />
}

const renderTransaction = (gainCoins, type, value) => {
  const newValue = value < 0 ? value * -1 : value

  return <Styled.TransactionValue>
    {renderArrow(gainCoins)}
    {renderCoin(type)}
    {newValue}
  </Styled.TransactionValue>
}

export const TransactionValue = (props) => {
  const {
    value,
    gainCoins,
    type
  } = props

  return <Styled.TransactionValue>
    {value && type
      ? renderTransaction(gainCoins, type, value)
      : renderCoin(type)
    }
  </Styled.TransactionValue>
}
