import { createTheme, ThemeProvider } from '@mui/material'
import { indigo, green, grey } from '@mui/material/colors'

// COMPONENTS
import Router from './routes/Router'

// STYLES
import * as Styled from './styles'

export default function App(props) {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: indigo[500],
        dark: indigo[800],
      },
      success: {
        main: green[600],
        dark: green[900],
      },
    },
  })

  return (
    <ThemeProvider theme={darkTheme}>
      <Styled.Container>
        <Router />
      </Styled.Container>
    </ThemeProvider>
  )
}
