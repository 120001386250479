import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const OldValue = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 52px;
  background: #DBDBDB;
  border-radius: 2px;
  color: #6A6A6A;
`

export const NewValue = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 52px;
  background: #0AB844;
  border-radius: 2px;
  color: white;
`

export const Divisor = styled.div`
  width: 1px;
  height: 24px;
  background: #C4C4C4;
  margin: 3px;
`