import React from 'react'

// STYLES
import * as Styled from './styles'

import { BalanceDiffProps } from './types'

export const BalanceDiff = ({ oldValue, newValue}: BalanceDiffProps) => {
  return <Styled.Container>
    {oldValue && <Styled.OldValue>{oldValue}</Styled.OldValue>}
    {oldValue && newValue && <Styled.Divisor />}
    {newValue && <Styled.NewValue>{newValue}</Styled.NewValue>}
  </Styled.Container>
}
