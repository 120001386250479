import { Pagination } from './SharedTypes'

export interface WalletSummary {
  user: User
  wallet: Wallet
  errors: string[]
  statusCode: number
}

export interface WalletReason {
  reasons: Reason[]
}

export interface WalletEvents {
  pagination: Pagination
  events: Events[]
  errors: string[]
  statusCode: number
}

export interface WalletCoinsDebits {
  pagination: Pagination
  transactions: Transactions[]
  errors: string[]
  statusCode: number
}

export interface WalletCoinsGifts {
  pagination: Pagination
  transactions: Transactions[]
  errors: string[]
  statusCode: number
}

export interface WalletRequestParams {
  limit: number
  page: number
}

export interface User {
  accountId: string
  nickname?: string
  playerId?: number
}

export interface WalletLastTransactions {
  date?: string
  hardCoinsAmount?: number
  softCoinsAmount?: number
  type?: string
}

export interface Wallet {
  hardCoinsBalance: number
  lastTransaction?: WalletLastTransactions
  softCoinsBalance: number
}

export interface WalletBalance {
  account: {
    id: string
  }
  id: string
  hardBalance: number
  lastTransaction: WalletLastTransactions
  softBalance: number
}
export interface UpdateBalancePayload {
  accountId: string
  transactionType: string
  coinsType: string
  amount: number
  reasonId: string
}

export interface Reason {
  deletedAt: string
  id: number
  reason: string
}

export interface Events {
  id: string
  createdAt: string
  hardCoinsAmount: number
  reason: string
  softCoinsAmount: number
  type: string
}

export interface Balance {
  new: number
  old: number
}

export interface Transactions {
  balance: Balance
  deletedAt: string
  eventSource?: string
  gift?: Gifts
  id: string
  itemId: number
  name: string
  status: string
  transactionDate: string
  value: number
}

export enum TransactionTypeEnum {
  CREATED_WALLET = 'CREATED_WALLET',
  CREDITED_WALLET = 'CREDITED_WALLET',
  DEBITED_WALLET = 'DEBITED_WALLET',
}

export enum CurrencyTypeEnum {
  softCoins = 'SILVER',
  hardCoins = 'GOLD',
}

export enum ProductStatusEnum {
  AVAILABLE = 'Disponível',
  USED = 'Usado',
  ACTIVATED = 'Em uso',
  REVERSED = 'Estornado',
}

export interface RemoveProductRequestProps {
  data: InventoryItem
  errors: string[]
  statusCode: number
}

export interface InventoryItem {
  accountId: string
  activationDate: string | null
  activationValue: string | null
  cartId: string
  category: string
  createdAt: string
  deletedAt: string | null
  deliveryPayload: string | null
  deliveryResponse: string | null
  expirationDate: string | null
  fromAccountId: string | null
  id: number
  metadata: any
  origin: string
  platform: string
  prizeId: number | null
  productId: string | null
  quantity: number
  slug: string
  status: string
  subscriptionId: string | null
  updatedAt: string
}

export interface SendCreditsPayload {
  accountIds: string[]
  credits: Credits
}

export interface SendCreditsResponse {
  statusCode: number
  errors: string[]
  data: {
    errors: string[]
  }
}
export interface Credits {
  softCoins: number
  hardCoins: number
}

export interface Gifts {
  cartId: string
  id: string
  message: string
  receiverPlayerId: string
  senderPlayerId: string
}
