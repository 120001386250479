import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { HeaderTitle, NotFound } from '@components/shared'
import { LoadingButton } from '@mui/lab'
import {
  Card,
  Stack,
  CardContent,
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  Tab,
  Tabs,
  LinearProgress,
  Tooltip,
} from '@mui/material'

import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { Modal } from './Modal/Modal'

// ICONS
import { Person, PersonSearch } from '@mui/icons-material'

// SERVICES
import {
  getWalletSummary,
  getWalletCoinsTransactions,
  getWalletGiftsTransactions,
  getWalletEventsTransactions,
  deleteInventoryItemFromPlayer,
} from '@services/coins/CoinsService'

// ENV
import { HOST_URL } from '@config/environment'

// TYPES
import {
  User,
  Wallet,
  Events,
  WalletEvents,
  WalletSummary,
  Transactions,
  WalletCoinsDebits,
  WalletCoinsGifts,
  WalletRequestParams,
  CurrencyTypeEnum,
  TransactionTypeEnum,
  ProductStatusEnum,
} from '@shared/types/CoinsTypes'

// STYLES
import * as Styled from './PlayerWallet.styles'
import { indigo } from '@mui/material/colors'
import { TransactionValue } from '@components/shared/TransactionValue/TransactionValue'
import { BalanceDiff } from './BalanceDiff/BalanceDiff'
import { formatDate } from '@shared/utils/FormatDate'

const PlayerWallet = (): JSX.Element => {
  const [tabValue, setTabValue] = useState(0)
  const [rowCountGold, setRowCountGold] = useState(0)
  const [rowCountSilver, setRowCountSilver] = useState(0)
  const [rowCountAll, setRowCountAll] = useState(0)
  const [rowCountGifts, setRowCountGifts] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingGold, setLoadingGold] = useState(false)
  const [loadingSilver, setLoadingSilver] = useState(false)
  const [loadingAll, setLoadingAll] = useState(false)
  const [loadingGift, setLoadingGift] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [gcId, setGcId] = useState<string>('')
  const [userSummary, setUserSummary] = useState<User | null>()
  const [walletSummary, setWalletSummary] = useState<Wallet | null>()
  const [goldPurchases, setGoldPurchases] = useState<Transactions[]>([])
  const [silverPurchases, setSilverPurchases] = useState<Transactions[]>([])
  const [giftsPurchases, setGiftsPurchases] = useState<Transactions[]>([])
  const [events, setEvents] = useState<Events[]>([])
  const [addCoins, setAddCoins] = useState<boolean>(false)

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      _getWalletUserSummary(id)
    }
  }, [])

  function handleChange(event, newTableValue) {
    setTabValue(newTableValue)
  }

  const handleClick = (addCoins): void => {
    setOpenModal(true)
    setAddCoins(addCoins)
  }

  const handleClose = () => setOpenModal(false)

  const _handleInputChange = (value: string): void => {
    setGcId(value)
  }

  const _handleItemDeletion = async (
    itemId: number,
    name: string
  ): Promise<void> => {
    Alert.remove(
      `Você está prestes a remover o produto ${name} do inventário do player e essa ação não pode ser desfeita. Deseja prosseguir?`
    )
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteInventoryItemFromPlayer(itemId)

          Alert.success('Produto removido com sucesso.', _handleSearch())
        }
      })
      .catch((e) => {
        Alert.error('Não foi possível remover o item')
      })
  }

  const _handleSearch = (): void => {
    if (gcId === '') return

    _getWalletUserSummary(gcId)
  }

  useEffect(() => {
    const queryParams: WalletRequestParams = {
      page: 1,
      limit: 10,
    }
    if (!userSummary) return

    _getWalletGoldTransactions(queryParams)
    _getWalletSilverTransactions(queryParams)
    _getWalletEventsTransactions(queryParams)
    _getWalletGiftsTransactions(queryParams)
  }, [userSummary])

  const _getWalletUserSummary = async (id: string): Promise<void> => {
    try {
      const summaryData: WalletSummary = await getWalletSummary(id)
      setUserSummary(summaryData.user)
      setWalletSummary(summaryData.wallet)
    } finally {
      setLoading(false)
    }
  }

  const _getWalletGoldTransactions = async (
    params: WalletRequestParams
  ): Promise<void> => {
    try {
      setLoadingGold(true)
      const goldPurchaseData: WalletCoinsDebits =
        await getWalletCoinsTransactions(
          'hardCoins',
          userSummary.accountId,
          params
        )
      userSummary && setGoldPurchases(goldPurchaseData.transactions)
      setRowCountGold(goldPurchaseData.pagination.totalItems)
    } finally {
      setLoadingGold(false)
    }
  }

  const _getWalletSilverTransactions = async (
    params: WalletRequestParams
  ): Promise<void> => {
    try {
      setLoadingSilver(true)
      const silverPurchaseData: WalletCoinsDebits =
        await getWalletCoinsTransactions(
          'softCoins',
          userSummary.accountId,
          params
        )
      userSummary && setSilverPurchases(silverPurchaseData.transactions)
      setRowCountSilver(silverPurchaseData.pagination.totalItems)
    } finally {
      setLoadingSilver(false)
    }
  }

  const _getWalletGiftsTransactions = async (
    params: WalletRequestParams
  ): Promise<void> => {
    try {
      setLoadingGift(true)
      const giftsPurchaseData: WalletCoinsGifts =
        await getWalletGiftsTransactions(userSummary.accountId, 'GIFT', params)
      userSummary && setGiftsPurchases(giftsPurchaseData.transactions)
      setRowCountGifts(giftsPurchaseData.pagination.totalItems)
    } finally {
      setLoadingGift(false)
    }
  }

  const _getWalletEventsTransactions = async (
    params: WalletRequestParams
  ): Promise<void> => {
    try {
      setLoadingAll(true)
      const eventsData: WalletEvents = await getWalletEventsTransactions(
        userSummary.accountId,
        params
      )
      userSummary && setEvents(eventsData.events)
      setRowCountAll(eventsData.pagination.totalItems)
    } finally {
      setLoadingAll(false)
    }
  }

  function TabPanel(props) {
    const { children, value, index } = props

    return (
      <Box
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Box>
    )
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const renderActionButtons = (
    itemId: number,
    name: string,
    date
  ): JSX.Element => {
    const isColumnDisabled = !!date

    return (
      <Button
        onClick={(): Promise<void> => _handleItemDeletion(itemId, name)}
        disabled={isColumnDisabled}
        color="error"
        variant="contained"
      >
        Remover
      </Button>
    )
  }

  const renderDateOpened = (date): JSX.Element | string => {
    if (!date) {
      return <p>Data não cadastrada</p>
    }

    if (date === ' - ') {
      return <p>Data inválida</p>
    }

    return formatDate(date, 'PP - KK:mm a')
  }

  const _renderWalletUserInfo = (): JSX.Element => {
    if (!!userSummary) {
      ;<p>Nenhum item encontrado para o usuário {gcId}</p>
    }
    return (
      <Card sx={{ mt: 2, mx: 'auto', width: 1000 }}>
        <CardContent sx={{ p: 3 }}>
          <form>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <Typography variant="button" display="block" gutterBottom>
                  Nickname
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {userSummary?.nickname}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="button" display="block" gutterBottom>
                  ID do usuário v1
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {userSummary?.playerId}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="button" display="block" gutterBottom>
                  ID do usuário
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {userSummary?.accountId}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <Typography variant="button" display="block" gutterBottom>
                  Quantidade na Carteira
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  <Stack direction="row" spacing={1}>
                    <TransactionValue
                      value={walletSummary?.hardCoinsBalance}
                      type={CurrencyTypeEnum.hardCoins}
                    />
                    <TransactionValue
                      value={walletSummary?.softCoinsBalance}
                      type={CurrencyTypeEnum.softCoins}
                    />
                  </Stack>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="button" display="block" gutterBottom>
                  Data da última transação
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {walletSummary && walletSummary.lastTransaction
                    ? formatDate(walletSummary?.lastTransaction.date, 'PP - KK:mm a')
                    : 'Sem transação'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="button" display="block" gutterBottom>
                  Valor da última transação
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {walletSummary && walletSummary.lastTransaction ? (
                    <>
                      {walletSummary?.lastTransaction.softCoinsAmount > 0 && (
                        <TransactionValue
                          value={walletSummary?.lastTransaction.softCoinsAmount}
                          type={CurrencyTypeEnum.softCoins}
                          gainCoins={
                            walletSummary?.lastTransaction.type ===
                            TransactionTypeEnum.CREDITED_WALLET
                          }
                        />
                      )}
                      {walletSummary?.lastTransaction.hardCoinsAmount > 0 && (
                        <TransactionValue
                          value={walletSummary?.lastTransaction.hardCoinsAmount}
                          type={CurrencyTypeEnum.hardCoins}
                          gainCoins={
                            walletSummary?.lastTransaction.type ===
                            TransactionTypeEnum.CREDITED_WALLET
                          }
                        />
                      )}
                    </>
                  ) : (
                    'Sem transação'
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0.2 }}>
              <Grid item xs>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClick(false)}
                    type="submit"
                    sx={{ mr: 2 }}
                  >
                    Remover moedas
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => handleClick(true)}
                  >
                    Adicionar moedas
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    )
  }

  const _renderGoldPurchases = (value: number, index: number): JSX.Element => {
    const goldColumns: GridColumns = [
      {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'name',
        headerName: 'Nome do Item',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 250,
      },
      {
        field: 'value',
        headerName: 'Valor',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 90,
        renderCell: (params) => (
          <TransactionValue
            value={params.row.value}
            type={CurrencyTypeEnum.hardCoins}
          />
        ),
      },
      {
        field: 'balance',
        headerName: 'Saldo de gold',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => (
          <BalanceDiff
            oldValue={params.value.old}
            newValue={params.value.new}
          />
        ),
      },
      {
        field: 'transactionDate',
        headerName: 'Data da transação',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => formatDate(params.row.transactionDate, 'PP - KK:mm a'),
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => ProductStatusEnum[params.row.status],
      },
      {
        field: 'deletedAt',
        headerName: 'Deletado',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => renderDateOpened(params.row.deletedAt),
      },
      {
        field: 'actions',
        headerName: 'Ações',
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) =>
          renderActionButtons(
            params.row.itemId,
            params.row.name,
            params.row.deletedAt
          ),
      },
    ]

    return (
      <Card hidden={value !== index} sx={{ mt: 2 }}>
        <CardContent sx={{ p: 3 }}>
          {goldPurchases?.length > 0 ? (
            <Card
              sx={{
                mt: 4,
                ['& .MuiDataGrid-iconSeparator']: {
                  display: 'none',
                },
              }}
            >
              <DataGrid
                paginationMode="server"
                key="goldPurchases"
                rowCount={rowCountGold}
                rows={goldPurchases}
                columns={goldColumns}
                loading={loadingGold}
                getRowId={(row) => row.id}
                pageSize={10}
                rowsPerPageOptions={[4]}
                autoHeight
                rowHeight={60}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: renderCustomLoadingOverlay,
                }}
                sx={{
                  ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                    outline: 'none',
                  },
                  ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                    whiteSpace: 'break-spaces',
                  },
                }}
                onPageChange={(page): Promise<void> =>
                  _getWalletGoldTransactions({
                    page: page + 1,
                    limit: 10,
                  })
                }
              />
            </Card>
          ) : (
            <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
          )}
        </CardContent>
      </Card>
    )
  }

  const _renderSilverPurchases = (
    value: number,
    index: number
  ): JSX.Element => {
    const silverColumns: GridColumns = [
      {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'name',
        headerName: 'Nome do Item',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 250,
      },
      {
        field: 'value',
        headerName: 'Valor',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 90,
        renderCell: (params) => (
          <TransactionValue
            value={params.row.value}
            type={CurrencyTypeEnum.softCoins}
          />
        ),
      },
      {
        field: 'balance',
        headerName: 'Saldo de silver',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => (
          <BalanceDiff
            oldValue={params.value.old}
            newValue={params.value.new}
          />
        ),
      },
      {
        field: 'transactionDate',
        headerName: 'Data da transação',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => formatDate(params.row.transactionDate, 'PP - KK:mm a'),
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => ProductStatusEnum[params.row.status],
      },
      {
        field: 'deletedAt',
        headerName: 'Deletado',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => renderDateOpened(params.row.deletedAt),
      },
      {
        field: 'actions',
        headerName: 'Ações',
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) =>
          renderActionButtons(
            params.row.itemId,
            params.row.name,
            params.row.deletedAt
          ),
      },
    ]

    return (
      <Card hidden={value !== index} sx={{ mt: 2 }}>
        <CardContent sx={{ p: 3 }}>
          {silverColumns?.length > 0 ? (
            <Card
              sx={{
                mt: 4,
                ['& .MuiDataGrid-iconSeparator']: {
                  display: 'none',
                },
              }}
            >
              <DataGrid
                paginationMode="server"
                key="silverPurchases"
                rowCount={rowCountSilver}
                rows={silverPurchases}
                columns={silverColumns}
                loading={loadingSilver}
                getRowId={(row) => row.id}
                pageSize={10}
                rowsPerPageOptions={[4]}
                autoHeight
                rowHeight={80}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: renderCustomLoadingOverlay,
                }}
                sx={{
                  ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                    outline: 'none',
                  },
                  ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                    whiteSpace: 'break-spaces',
                  },
                }}
                onPageChange={(page): Promise<void> =>
                  _getWalletSilverTransactions({
                    page: page + 1,
                    limit: 10,
                  })
                }
              />
            </Card>
          ) : (
            <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
          )}
        </CardContent>
      </Card>
    )
  }

  const _renderAllTransactions = (
    value: number,
    index: number
  ): JSX.Element => {
    const eventsColumns: GridColumns = [
      {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 50,
      },
      {
        field: 'value',
        headerName: 'Valor',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => {
          const { type, softCoinsAmount, hardCoinsAmount } = params.row

          if (type === TransactionTypeEnum.CREATED_WALLET) return '-'

          return (
            <>
              {softCoinsAmount > 0 && (
                <TransactionValue
                  value={softCoinsAmount}
                  type={CurrencyTypeEnum.softCoins}
                  gainCoins={type === TransactionTypeEnum.CREDITED_WALLET}
                />
              )}
              {hardCoinsAmount > 0 && (
                <TransactionValue
                  value={hardCoinsAmount}
                  type={CurrencyTypeEnum.hardCoins}
                  gainCoins={type === TransactionTypeEnum.CREDITED_WALLET}
                />
              )}
            </>
          )
        },
      },
      {
        field: 'reason',
        headerName: 'Motivo',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
      },
      {
        field: 'createdAt',
        headerName: 'Data da transação',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => formatDate(params.row.createdAt, 'PP - KK:mm a'),
      },
    ]

    return (
      <Card hidden={value !== index} sx={{ mt: 2 }}>
        <CardContent sx={{ p: 3 }}>
          {events?.length > 0 ? (
            <Card
              sx={{
                mt: 4,
                ['& .MuiDataGrid-iconSeparator']: {
                  display: 'none',
                },
              }}
            >
              <DataGrid
                paginationMode="server"
                key="events"
                rowCount={rowCountAll}
                rows={events}
                columns={eventsColumns}
                loading={loadingAll}
                getRowId={(row) => row.createdAt || '-' || row.id}
                pageSize={10}
                rowsPerPageOptions={[4]}
                autoHeight
                rowHeight={80}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: renderCustomLoadingOverlay,
                }}
                sx={{
                  ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                    outline: 'none',
                  },
                  ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                    whiteSpace: 'break-spaces',
                  },
                }}
                onPageChange={(page): Promise<void> =>
                  _getWalletEventsTransactions({
                    page: page + 1,
                    limit: 10,
                  })
                }
              />
            </Card>
          ) : (
            <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
          )}
        </CardContent>
      </Card>
    )
  }

  const _renderGiftsTransactions = (
    value: number,
    index: number
  ): JSX.Element => {
    const giftsColumns: GridColumns = [
      {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 50,
      },
      {
        field: 'balance',
        headerName: 'Saldo de gold',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => (
          <BalanceDiff
            oldValue={params.value.old}
            newValue={params.value.new}
          />
        ),
      },
      {
        field: 'receiverPlayerId',
        headerName: 'Recebido por:',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => {
          const { receiverPlayerId } = params.row.gift
          return (
            <Styled.Link href={`${HOST_URL}/player/${receiverPlayerId}`}>
              {receiverPlayerId}
            </Styled.Link>
          )
        },
      },
      {
        field: 'message',
        headerName: 'Mensagem',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => {
          const { message } = params.row.gift
          return (
            <Tooltip title={message} placement="top">
              <Typography variant="caption" display="block" gutterBottom>
                <Button color="success">
                  Passe o mouse para ver a mensagem
                </Button>
              </Typography>
            </Tooltip>
          )
        },
      },
      {
        field: 'name',
        headerName: 'Nome do item',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => {
          const { name } = params.row

          return (
            <Typography variant="caption" display="block" gutterBottom>
              {name}
            </Typography>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Status do item',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.25,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => {
          const { status } = params.row
          if (status === ' - ') {
            return (
              <Typography variant="caption" display="block" gutterBottom>
                Aguardando o aceite
              </Typography>
            )
          }
          return (
            <Typography variant="caption" display="block" gutterBottom>
              {status}
            </Typography>
          )
        },
      },
      {
        field: 'transactionDate',
        headerName: 'Data de envio do presente',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 220,
        renderCell: (params) => formatDate(params.row.transactionDate, 'PP - KK:mm a'),
      },
      {
        field: 'actions',
        headerName: 'Ações',
        disableColumnMenu: true,
        sortable: false,
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        renderCell: (params) =>
          renderActionButtons(
            params.row.itemId,
            params.row.name,
            params.row.deletedAt
          ),
      },
    ]

    return (
      <Card hidden={value !== index} sx={{ mt: 2 }}>
        <CardContent sx={{ p: 3 }}>
          {giftsPurchases?.length > 0 ? (
            <Card
              sx={{
                mt: 4,
                ['& .MuiDataGrid-iconSeparator']: {
                  display: 'none',
                },
              }}
            >
              <Typography variant="h4" display="block" gutterBottom>
                Presentes
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                * O nome do item e status do item só serão alterados quando o
                usuário aceitar o presente.
              </Typography>
              <DataGrid
                paginationMode="server"
                key="giftsPurchases"
                rowCount={rowCountGifts}
                rows={giftsPurchases}
                columns={giftsColumns}
                loading={loadingGift}
                getRowId={(row) => row.id}
                pageSize={10}
                rowsPerPageOptions={[4]}
                autoHeight
                rowHeight={80}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: renderCustomLoadingOverlay,
                }}
                sx={{
                  ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                    outline: 'none',
                  },
                  ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                    whiteSpace: 'break-spaces',
                  },
                }}
                onPageChange={(page): Promise<void> =>
                  _getWalletGiftsTransactions({
                    page: page + 1,
                    limit: 10,
                  })
                }
              />
            </Card>
          ) : (
            <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
          )}
        </CardContent>
      </Card>
    )
  }

  return (
    <Styled.Container>
      <HeaderTitle
        title={`Wallet do player ${userSummary ? userSummary.nickname : ''}`}
      >
        <Person fontSize="large" style={{ fill: indigo[500] }} />
      </HeaderTitle>
      <Styled.SearchForm>
        <TextField
          label="GC ID do jogador"
          variant="outlined"
          value={gcId}
          onChange={(e): void => _handleInputChange(e.target.value)}
          disabled={loading}
          placeholder="210522"
        />
        <LoadingButton
          variant="contained"
          onClick={(): void => _handleSearch()}
          disabled={loading}
          loading={loading}
        >
          <PersonSearch /> Buscar
        </LoadingButton>
      </Styled.SearchForm>
      {!userSummary ? (
        <p>Busque a carteira do player usando o gcID do player acima</p>
      ) : (
        <>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Carteira" />
            <Tab label="Compras com Gold" />
            <Tab label="Compras com Silver" />
            <Tab label="Movimentações da carteira" />
            <Tab label="Lista de Presentes" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {_renderWalletUserInfo()}
          </TabPanel>
          {_renderGoldPurchases(tabValue, 1)}
          {_renderSilverPurchases(tabValue, 2)}
          {_renderAllTransactions(tabValue, 3)}
          {_renderGiftsTransactions(tabValue, 4)}
        </>
      )}
      <Modal
        handleClose={() => handleClose()}
        open={openModal}
        accountId={userSummary?.accountId}
        addCoins={addCoins}
      />
    </Styled.Container>
  )
}

export { PlayerWallet }