import { useEffect, useRef, useState } from 'react'

// TYPES
import { CsvUploadProps } from './types'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import { Button } from '@mui/material'

const CsvUpload = ({
  addButtonProps,
  restrictions,
  handleSubmit,
  defaultCsv,
}: CsvUploadProps) => {
  const [csvFile, setCsvFile] = useState<any>('')
  useEffect(() => {
    setCsvFile(defaultCsv)
  }, [defaultCsv])

  const fileInput = useRef<any>()

  const handleCsvChange = (e) => {
    e.preventDefault()

    const rawFile = e.target.files[0]

    if (!rawFile) return

    const reader = new window.FileReader()

    reader.onloadend = () => {
      setCsvFile(reader.result)
      handleSubmit(rawFile, reader.result)
    }

    if (rawFile) {
      reader.readAsDataURL(rawFile)
    }
  }

  const handleClick = () => {
    fileInput.current.value = ''
    fileInput.current.click()
  }

  return (
    <Styled.InputContainer>
      <Styled.InputFile
        type="file"
        onChange={handleCsvChange}
        ref={fileInput}
        accept={restrictions}
      />
      <Button
        {...addButtonProps}
        variant="contained"
        onClick={() => handleClick()}
      >
        {csvFile ? 'Alterar CSV' : 'Selecionar CSV'}
      </Button>
    </Styled.InputContainer>
  )
}

export { CsvUpload }
