import { CreditsForm } from '@components/Credits'
import { PlayerWallet } from '@components/PlayerWallet'

type Route = {
  component: JSX.Element
  exact: boolean
  path: string
}

const routes = (): Route[] => [
  // CATEGORIES
  {
    component: <PlayerWallet />,
    exact: true,
    path: '/apps/coins/wallet/',
  },
  {
    component: <PlayerWallet />,
    exact: true,
    path: '/apps/coins/wallet/:id',
  },
  {
    component: <CreditsForm />,
    exact: true,
    path: '/apps/coins/credits/upload',
  },
]

export default routes
